<template>
  <section id="questionary">
    <div class="wrapper mobile" :class="{mobile: $isMobile()}">
      <form>
        <div>
            <a class="subheader" >{{ todayDate }}</a>
        </div>
        <div id="quest_block1">
            <h1>{{ $t('quest_question1_title') }}:</h1>
            <div>
              <input type="text">
            </div>
        </div>
        <div id="quest_block2">
            <h1>{{ $t('quest_question2_title') }}:</h1>
            <div>
                <div class="table-col3-mob1 table-with-labels">
                  <div>
                    <div>
                      <div v-html="$t('quest_question2_label1')"></div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/car.png" v-bind:alt="$t('quest_car_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/suv.png" v-bind:alt="$t('quest_suv_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/moto.png" v-bind:alt="$t('quest_moto_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                    </div>
                    <div>
                      <div v-html="$t('quest_question2_label2')"></div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/car.png" v-bind:alt="$t('quest_car_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/suv.png" v-bind:alt="$t('quest_suv_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/moto.png" v-bind:alt="$t('quest_moto_alt')">
                        </a>
                        <input type="number" min="0" step="0.25"">
                      </div>
                    </div>
                    <div>
                      <div v-html="$t('quest_question2_label3')"></div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/car.png" v-bind:alt="$t('quest_car_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/suv.png" v-bind:alt="$t('quest_suv_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                      <div>
                        <a class="row-label">
                          <img src="~@/assets/images/icons/moto.png" v-bind:alt="$t('quest_moto_alt')">
                        </a>
                        <input type="number" min="0" step="0.25">
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div id="quest_block3">
          <h1>{{ $t('quest_question3_title') }}:</h1>
          <div>
            <div class="table-col2-mob1">
              <div>
                <div>
                  <div class="discounts-input" ref="discountsInput1">
                    <div>
                      <div v-html="$t('quest_question3_label1')"></div>
                      <div v-html="$t('quest_question3_label2')"></div>
                    </div>
                    <div>
                      <div v-html="$t('quest_question3_label5')"></div>
                      <div v-html="$t('quest_question3_label6')"></div>
                      <div v-html="$t('quest_question3_label7')"></div>
                      <div v-html="$t('quest_question3_label8')"></div>
                    </div>
                    <div>
                      <div>
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                      <div> 
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                    </div>
                    <div>
                      <div>
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                      <div> 
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="discounts-input" ref="discountsInput2">
                    <div>
                      <div v-html="$t('quest_question3_label3')"></div>
                      <div v-html="$t('quest_question3_label4')"></div>
                    </div>
                    <div>
                      <div v-html="$t('quest_question3_label7')"></div>
                      <div v-html="$t('quest_question3_label8')"></div>
                      <div v-html="$t('quest_question3_label7')"></div>
                      <div v-html="$t('quest_question3_label8')"></div>
                    </div>
                    <div>
                      <div>
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                      <div> 
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                    </div>
                    <div>
                      <div>
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                      <div> 
                        <input type="text">
                      </div>
                      <div>
                        <input type="text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button v-on:click="addDiscountsInput()" type="button" class="round-btn">{{ $t('quest_button1_label') }}</button>
            </div>
          </div>
        </div>
        <div id="quest_block4">
          <h3>{{ $t('quest_question4_title') }}:</h3>
          <div>
            <ul>
              <li>{{ $t('quest_question4_text1') }}</li>
              <li>{{ $t('quest_question4_text2') }}</li>
              <li>{{ $t('quest_question4_text3') }}</li>
            </ul>
          </div>
        </div>
        <div id="quest_block6">
          <h1>{{ $t('quest_question6_title') }}:</h1>
          <div>
            <label>{{ $t('quest_question6_label1') }}:</label>
            <input type="tel">
          </div>
          <div>
            <label>{{ $t('quest_question6_label2') }}:</label>
            <input type="text">
          </div>
          <div>
            <label>{{ $t('quest_question6_label3') }}:</label>
            <input type="tel">
          </div> 
        </div>
        <div id="quest_block7">
          <h1>{{ $t('quest_question7_title') }}:</h1>
          <div>
            <label>{{ $t('quest_question7_label1') }}:</label>
            <input type="text">
          </div>
        </div>
        <div class="center-wrapper">
          <button class="btn" type="button">{{ $t('quest_button2_label') }}</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { email, required, maxLength } from 'vuelidate/lib/validators';

export default {
  components: {
  },
  computed: {
    todayDate(){
      const today = new Date();
      return today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
    }
  },
  // validations: {
  //   contactMail: {
  //     required,
  //     email
  //   }
  // },
  methods: {
    addDiscountsInput(){
      const html = '<div><div><input type="text"></div><div><input type="text"></div><div><input type="text"></div><div><input type="text"></div></div>'
      this.$refs.discountsInput1.innerHTML += html;
      this.$refs.discountsInput2.innerHTML += html;
    }
  }
}
</script>