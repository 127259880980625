<template>
    <section id="for_drivers">
        <div id="info" :class="{mobile: $isMobile()}">
            <!-- Link tag -->
        </div>
        <div class="wrapper">
            <h1 class="title">{{ $t('driver_title_1') }}</h1>
            <div class="text-video inline mob-col">
                <p class="left-col" v-html="$t('driver_leftText_1')"></p>
                <Youtube video_id="BkweWSNHivA" class="right-col"></Youtube>
            </div>
            <div class="promo">
                <p class="text-size1" v-html="$t('driver_leftText_2')"></p>
                <img src="~@/assets/images/car.png" />
            </div>
        </div>
        <MobileSteps v-if="$isMobile()"></MobileSteps>
        <DownloadLine v-if="$isMobile()"></DownloadLine>
        <DesktopSteps v-else></DesktopSteps>
        <div class="wrapper">
            <h1 class="title special">{{ $t('driver_title_2') }}</h1>
            <div class="text-video inline _second mob-col">
                <p class="left-col text-size1" v-html="$t('driver_leftText_3')"></p>
                <div class="right-col">
                    <Youtube video_id="OqEf_y7DIkY"></Youtube>
                    <div class="separator"></div>
                </div>
            </div>
            <div class="inline mob-col">
                <p class="left-col text-size1" v-html="$t('driver_leftText_4')"></p>
                <div class="right-col">
                    <Faq></Faq>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Youtube from './parts/Youtube.vue'
import Faq from './parts/FaqDriver.vue'

import MobileSteps from './ForDriversStepsMobile.vue'
import DesktopSteps from './ForDriversStepsDesktop.vue'
import DownloadLine from './DownloadLine.vue'

export default {
    components: {
        Youtube,
        Faq,
        MobileSteps,
        DesktopSteps,
        DownloadLine
    },
    created () {
        history.replaceState(null, null, ' ');
    }
}
</script>