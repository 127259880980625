import Vue from 'vue'
import VueRouter from 'vue-router'

import ForDrivers from '../components/ForDrivers.vue'
import ForParkingmans from '../components/ForParkingmans.vue'
import Questionary from '../components/Questionary.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/drivers'
    },
    {
        path: '/app/',
        redirect: '/owners'
    },
    {
        path: '/drivers',
        name: 'drivers',
        component: ForDrivers
    },
    {
        path: '/owners',
        name: 'owners',
        component: ForParkingmans
    }
    // {
    //     path: '/owners/form',
    //     name: 'form',
    //     component: Questionary
    // }
]

const router = new VueRouter({
    mode: 'history',
    hash:false,
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'
            }
        }
    }
})

export default router
